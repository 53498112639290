import yuleOgImage from '~/assets/yuleog-bc80d0645d1ecd3778db41d9b7551d9b/a99.png';
import { useFeatureFlag } from '~/utils/useFeatureFlag';
import CircularPicture from './CircularPicture';
import type { ComponentProps } from 'react';

type Props = {
  user: {
    profilePicture: string;
    profilePicturePlaceholder?: string;
    username: string;
  };
  size: number;
  className?: string;
  alt?: string;
} & Omit<ComponentProps<typeof CircularPicture>, 'src' | 'alt'>;

const ProfilePicture = ({
  user,
  size = 100,
  className,
  alt = `${user.username} sitt profilbilde`,
  style,
  ...props
}: Props) => {
  const showEgg = useFeatureFlag('easter2025');

  return (
    <>
      {!showEgg && (
        <CircularPicture
          alt={alt}
          src={user.profilePicture}
          placeholder={user.profilePicturePlaceholder}
          size={size}
          className={className}
          style={style}
          {...props}
        />
      )}
      {showEgg && (
        <CircularPicture
          alt={alt}
          src={user.username == 'magnbrec' ? yuleOgImage : user.profilePicture}
          placeholder={user.profilePicturePlaceholder}
          size={size}
          className={className}
          style={style}
          {...props}
        />
      )}
    </>
  );
};

export default ProfilePicture;
